<template>
  <b-container fluid class="pt-3">
    <b-row>
      <b-form-select v-model="selected">
        <b-form-select-option :value="null"
          >Please select an assessment</b-form-select-option
        >
        <b-form-select-option value="base"
          >Base Assessment</b-form-select-option
        >
        <b-form-select-option value="risk"
          >Risk Assessment</b-form-select-option
        >
      </b-form-select>
      <div class="d-flex flex-wrap mt-5" v-if="selected !== null">
        <b-col
          v-for="form in profileFarms"
          :key="form.recordNumber"
          cols="auto"
        >
          <b-card
            @click="$emit('show-modal', form.recordNumber)"
            :bg-variant="form.is_approved == 'true' ? 'primary' : 'secondary'"
            text-variant="light"
          >
            {{ form.form_name }}
          </b-card>
        </b-col>
      </div>
    </b-row>
  </b-container>
</template>

<!-- The forms need to be updated when new forms are coming in -->
<script>
export default {
  data() {
    return {
      form: [],
      form_data: [],
      selected: null
    };
  },
  name: "TableForms",
  props: ["farm_profile", "formList"],
  computed: {
    profileFarms() {
      return this.formList.filter(
        form =>
          form.farm_profile === this.farm_profile &&
          form.form_type === this.selected
      );
    }
  }
};
</script>
