<template>
  <!-- :options="pdfOptions" -->
  <!-- <vue3-simple-html2pdf
    ref="vue3SimpleHtml2pdf"
    filename="Certificate.pdf"
  > -->
  <div>
    <b-table-simple small bordered v-if="form_data">
      <b-thead>
        <b-tr>
          <b-th colspan="1">FARM:</b-th>
          <b-th colspan="6" class="text-uppercase"
            >FFF risk assessment summary</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th variant="success">SECONDARY SUPPLIER</b-th>
          <b-td colspan="4">{{ form_data.secondary.supplier }}</b-td>
          <b-th variant="success"> ASSESSMENT DATE</b-th>
          <b-td>
            {{ form_data.assessor.date | moment("L") }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th variant="success">FARM ASSESSED</b-th>
          <b-td colspan="3"></b-td>
          <b-th variant="success">REPRESENTATIVE & CAPACITY</b-th>
          <b-td colspan="2">{{ form_data.secondary.representative }}</b-td>
        </b-tr>
        <b-tr>
          <b-th variant="success">PRIMARY SUPPLIER</b-th>
          <b-td colspan="3">{{ form_data.primary.supplier }}</b-td>
          <b-th variant="success">REPRESENTATIVE(S)</b-th>
          <b-td colspan="2">{{ form_data.primary.representative }}</b-td>
        </b-tr>
        <b-tr>
          <b-th variant="success">MAIN CROP(S)</b-th>
          <b-td colspan="4">{{ form_data.secondary.crop_types }}</b-td>
          <b-th variant="success">PERCENTAGE TO WOOLWORTHS</b-th>
          <b-td>{{ form_data.primary.percentage }}</b-td>
        </b-tr>
        <b-tr>
          <b-th variant="success">FARMING PRACTICES</b-th>
          <b-th variant="success">SOIL</b-th>
          <b-td></b-td>
          <b-th variant="success">HYDROPONIC</b-th>
          <b-td></b-td>
          <b-th variant="success">DESCRIPTION</b-th>
          <b-td></b-td>
        </b-tr>
        <b-tr>
          <b-th variant="success">PACKHOUSE</b-th>
          <b-td colspan="2">{{ form_data.secondary.pack_house }}</b-td>
          <b-th variant="success">DESCRIPTION</b-th>
          <b-td colspan="3"></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple small borderless v-if="cert_data" class="border">
      <b-tbody>
        <template v-for="(row, i) in cert_data.farm_cert">
          <b-tr :key="'empty_row_' + i">
            <b-td colspan="5" class="py-2"> </b-td>
          </b-tr>
          <b-tr class="border-bottom" :key="'row_' + i">
            <b-th colspan="2" class="text-uppercase">
              {{ row.assessment_name }}
            </b-th>
            <b-th
              class="border text-center"
              :class="row.performance_rate | colorange"
            >
              {{ row.performance_rate }}
            </b-th>
            <b-th>PERFORMANCE RATING</b-th>
            <b-th>Responsibility</b-th>
          </b-tr>
          <b-tr
            v-for="(form, v) in row.forms"
            :key="form.form_name + '_' + i + '_' + v"
          >
            <b-th>{{ form.form_name }}</b-th>
            <b-th class="border text-center" :class="form.rating | colorange">
              {{ form.rating }}
            </b-th>
            <b-td colspan="2">
              <b-form-input
                @change="$emit('cert-comments', cert_data)"
                v-if="addComment === true"
                v-model="form.comment"
              />
              <p v-if="addComment === false">{{ form.comment }}</p>
            </b-td>
            <b-td></b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>

    <div class="d-flex flex-row my-4">
      <h6 class="flex-fill text-center text-uppercase font-weight-bold">
        Status recommendation:
      </h6>
      <div class="flex-fill text-center" :class="color">
        <h5 class="font-weight-bold">{{ comment }}</h5>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-end my-4">
      <h6 class="font-weight-bold mr-2">Additional notes:</h6>
      <b-form-input
        v-if="addComment === true"
        @change="$emit('cert-comments', cert_data)"
        v-model="cert_data.additional_notes"
        class="w-75"
      />
      <p v-if="addComment === false" class="w-75">
        {{ cert_data.additional_notes }}
      </p>
    </div>

    <div class="img_container d-flex justify-content-center" v-if="image">
      <img :src="image" class="img_sign" />
    </div>

    <div class="sign_container" v-if="imgSign">
      <img :src="imgSign" class="img_sign2" crossorigin="*" />
    </div>

    <div class="w-25 mb-5 border-top border-dark">
      <p class="font-weight-bold m-0" v-if="addComment === false">
        {{ cert_data.assessor.fullname }}
      </p>
      <b-form-input
        v-if="addComment === true"
        @change="$emit('cert-comments', cert_data)"
        v-model="cert_data.assessor.fullname"
        placeholder="Full name"
      />
      <p v-if="addComment === false">{{ cert_data.assessor.role }}</p>
      <b-form-input
        v-if="addComment === true"
        @change="$emit('cert-comments', cert_data)"
        v-model="cert_data.assessor.role"
        placeholder="Role"
      />
    </div>

    <div class="d-flex flex-row my-4">
      <h6 class="font-weight-bold mr-2">WW REFERENCES:</h6>
      <b-form-input
        v-if="addComment === true"
        @change="$emit('cert-comments', cert_data)"
        v-model="cert_data.ww_references"
        class="w-75"
      />
      <p v-if="addComment === false" class="w-75">
        {{ cert_data.ww_references }}
      </p>
    </div>

    <div class="d-flex flex-row my-4">
      <h6 class="font-weight-bold mr-2">DISCLAIMER:</h6>
      <b-form-input
        v-if="addComment === true"
        @change="$emit('cert-comments', cert_data)"
        v-model="cert_data.disclaimer"
        class="w-75"
      />
      <p v-if="addComment === false" class="w-75">{{ cert_data.disclaimer }}</p>
    </div>

    <div v-if="showGuide == true" class="tblGuide">
      <b-table-simple bordered small>
        <b-thead>
          <b-tr>
            <b-th scope="col">Average</b-th>
            <b-th scope="col">{{ avgPerc }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th scope="row">below 50%</b-th>
            <b-td class="bg-bronze">Need Assistance before FFF</b-td>
          </b-tr>
          <b-tr>
            <b-th scope="row">50-70%</b-th>
            <b-td class="bg-silver">Potentially FFF ready</b-td>
          </b-tr>
          <b-tr>
            <b-th scope="row">>70%</b-th>
            <b-td class="bg-gold">Likely FFF Ready</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
  <!-- </vue3-simple-html2pdf>   -->
</template>

<script>
export default {
  data() {
    return {
      comment: "",
      color: ""
    };
  },
  props: ["formData", "certData", "addComment", "avgPerc", "showGuide", "image","imgSign"],
  computed: {
    cert_data() {
      return this.certData ? JSON.parse(this.certData) : null;
    },
    form_data() {
      return this.formData ? JSON.parse(this.formData) : null;
    }
  },
  mounted() {
    this.getColor();
  },
  methods: {
    getColor() {
      if (this.avgPerc < 50) {
        this.comment = "Need Assistance before FFF";
        this.color = "bg-bronze";
      } else if (this.avgPerc > 50 && this.avgPerc <= 70) {
        this.comment = "Potentially FFF ready";
        this.color = "bg-silver";
      } else if (this.avgPerc > 70) {
        this.comment = "Likely FFF Ready";
        this.color = "bg-gold";
      }
    }
  },
  filters: {
    colorange: value => {
      if (value > 9 && value < 50) {
        return "bg-bronze";
      } else if (value > 49 && value < 75) {
        return "bg-silver";
      } else if (value > 74) {
        return "bg-gold";
      } else if (value < 10) {
        return "bg-light";
      }
    }
  }
};
</script>

<style scoped>
h6 {
  font-size: 1.1rem;
}
.tblGuide {
  width: 288px;
}

.img_container {
  width: 265px;
  height: 137px;
}
/*
width: 348px;
height: 120px 
 */

.sign_container {
    width: 401px;
    height: 156px;
}
/* height : 120px */

.img_sign, .img_sign2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
