<template>
  <div>
    <b-container fluid>
      <!-- <pre>{{ tableData }}</pre> -->
      <!-- User Interface controls -->
      <b-row class="justify-content-between">
        <b-col cols="auto" v-if="canSelectAssess">
          <b-form-group
            label="Assessor"
            label-for="per-page-select"
            label-cols-sm="auto"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="assessor"
              :options="assessors"
              @change="filterForms()"
            ></b-form-select>
              <!-- size="sm" -->
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group>
            <b-input-group size="sm">
              <b-input-group-prepend>
                <b-input-group-text class="bg-white border-right-0">
                  <b-icon icon="search"></b-icon>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                class="border-left-0"
                id="filter-input"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="auto"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-table
        :fields="fields"
        :items="tableData"
        :per-page="perPage"
        :current-page="currentPage"
        head-variant="dark"
        primary-key="id"
        bordered
        show-empty
        small
        :filter="filter"
        :busy="tblBusy"
        ref="table"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="d-flex justify-content-end justify-content-around">
            <b-button size="sm" variant="warning" @click="row.toggleDetails">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details
            </b-button>
            <b-button
              size="sm"
              variant="dark"
              @click="getCertificate(row.item.uniqueId, row.item.title)"
            >
              View certificate
            </b-button>
            <b-button
              size="sm"
              variant="success"
              :to="{ name: 'farmer-details', params: row.item }"
            >
              <b-icon icon="eye-fill"></b-icon>
            </b-button>
          </div>
        </template>
        <template #cell(fff)="data">
          <span v-if="data.item.fff">
            {{ data.item.fff }}
          </span>
          <span v-else>Calculating...</span>
        </template>

        <template #row-details="row">
          <div>
            <TableForms
              :farm_profile="row.item.uniqueId"
              :formList="forms"
              @show-modal="showModal($event)"
            />
          </div>
        </template>
      </b-table>

      <b-row class="justify-content-center">
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>

        <!-- :html-to-pdf-options="pdfOptions" 
        pdf-content-width="900px"
        -->
    <b-modal id="certificate" title="Certificate" size="xl" scrollable>
      <vue-html2pdf
        :html-to-pdf-options="pdfOptions" 
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a3"
        pdf-orientation="portrait"
        pdf-content-width="100%"

        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        filename="Certificate"
        ref="html2Pdf"
      >
        <div slot="pdf-content">
          <Certificate
            :formData="latestForm.form_data"
            :certData="latestForm.cert_json"
            :avgPerc="avgPerc"
            :image="image"
            :imgSign="imgSign"
            :addComment="addComment"
            :showGuide="false"
            @cert-comments="certComments($event)"
          />
        </div>  
      </vue-html2pdf>  
       <Certificate
          :formData="latestForm.form_data"
          :certData="latestForm.cert_json"
          :avgPerc="avgPerc"
          :image="image"
          :imgSign="imgSign"
          :addComment="addComment"
          :showGuide="true"
          @cert-comments="certComments($event)"
        />
      <template #modal-footer>
        <b-button @click="addComments()"> Add Comment </b-button>
        <b-button @click="saveComments()" variant="feature"> Save </b-button>
        <b-button @click="addSignature()" variant="feature"> Add Signature </b-button>
        <b-button @click="generatePDF()" variant="primary"> Download </b-button>
      </template>
    </b-modal>

    <b-modal id="canvas" title="Signature" scrollable>
      <div id="app">
        <VueSignaturePad ref="signaturePad" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#212529'}" />
      </div>
      <template #modal-footer>
        <b-button @click="undo()"> Undo </b-button>
        <b-button @click="clear()" variant="primary"> Clear </b-button>
        <div class="file-input-container">
          <label>
            <input type="file" @change="getImage" ref="myFiles"/>
            Upload Image
          </label>
        </div>
        <b-button @click="save()" variant="feature"> Save </b-button>
      </template>
    </b-modal>


    <b-modal id="form-modal" centered scrollable>
      <div class="text-center" v-if="isBusy">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <template #modal-title>
        <h3>{{ form_det.form_name }}</h3>
      </template>
      <FormModal :form_det="form_det" />
      <template #modal-footer>
        <b-button @click="approveForm(form_det)" variant="success">
          Approve
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getProfileList, getMemberList } from "@/services/profile";
import { getForm, getCertificate, submitCert, approveForm } from "@/services/forms";
import TableForms from "@/components/Forms/TableForms";
import Certificate from "@/components/Certificate";
import FormModal from "@/components/Forms/FormModal";
import Vue from "vue";
import VueHtml2pdf from 'vue-html2pdf';

Vue.prototype.perPageF = null;

export default {
  components: {
    TableForms,
    Certificate,
    FormModal,
    VueHtml2pdf,
  },
  data() {
    return {
      pdfOptions: {
      margin: 5,
      filename: `Certificate.pdf`,
      jsPDF: {
          format: '',
          orientation: 'portrait'
      }
    },
      form_det: [],
      addComment: false,
      approve: false,
      avgPerc: null,
      imgSign:null,
      image:null,
      newComments: [],
      isBusy:false,
      tblBusy:false,
      forms: [],
      canSelectAssess:null,
      assessor:null,
      assessors:[{ value: null, text: 'Please select an assessor' }],
      tableData: [],
      latestForm: {
        cert_json: [],
        form_data: {}
      },
      filter: "",
      currentPage: 1,
      perPage: 10,
      pageOptions: [5,10, 15, { value: 100, text: "Show a lot" }],
      fields: [
        { key: "title", label: "Outgrower name in system" },
        { key: "fff", label: "FFF %" },
        { key: "actions", label: "" }
      ]
    };
  },
  computed: {
    totalRows() {
      return this.tableData.length ?? 0;
    }
  },
  mounted() {
    if (this.$auth.check()) {
      this.getProfileList();
      this.getForm();
      this.getMemberList();
      this.checkMembers();
      if (Vue.prototype.perPageF){
        this.perPage = Vue.prototype.perPageF
      }
    }
  },
  methods: {
    changeInstance(){
        Vue.prototype.perPageF = this.perPage
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      // const modalDimensions = this.$refs.html2Pdf.getClientRects();
      this.pdfOptions.jsPDF.format = [300, 540]
      this.$refs.html2Pdf.generatePdf();
    },
    addSignature(){
      this.$bvModal.show("canvas")
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      this.imgSign = null;
      this.image = data
      console.log(this.image, isEmpty)
      // fetch(data)
      // .then(res => res.blob())
      // .then(blob => {
      //   this.image = new File([blob], "Signature",{ type: "image/png" })
      //   console.log(this.image)
      //  })
      this.$bvModal.hide("canvas")
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    getImage(){
      console.log(this.$refs.myFiles.files[0])
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = null;
        this.$bvModal.hide("canvas")
        this.imgSign = e.target.result;
        console.log(this.imgSign)
      }
      reader.readAsDataURL(this.$refs.myFiles.files[0]);
    },
    async checkMembers(){
      const members = await getMemberList("zire-plus-dashboard")
      const signedUser = members.find(member => member.uniqueId === Vue.auth.user().userUniqueId)
      this.canSelectAssess = ["Champion","Manager","VIP"].includes(signedUser?.role)
    },
    async getMemberList(){
      await getProfileList().then(profiles => {
        profiles.forEach(async profile => {
          const members = await getMemberList(profile.uniqueId)
          const assessors = members?.filter(member => member.role === "Champion")
          if (assessors?.length > 0){
            assessors.forEach(assessor => {
              if (!this.assessors.includes(assessor.uniqueId)){
                this.assessors.push(assessor.uniqueId)
              }
            })
          }
        })
      })
    },
    filterForms(){
      this.getForm(this.assessor)
      this.$refs.table.refresh()
    },
    async approveForm(form) {
      this.isBusy = true
      const payload = {
        form_uuid: form.form_uuid,
        form_locked: form.form_locked,
        is_approved: "true",
        form_data: form.form_data
      }
      approveForm(payload).then(async ()=> await this.getForm())
      this.isBusy = false
      this.$bvModal.hide("form-modal");
    },
    async getAvgPerc(uniqueId) {
      const avg = await getCertificate(uniqueId).then(form_cert => {
        let totalScores = 0;
        const cert = form_cert?.cert_json
          ? JSON.parse(form_cert.cert_json).farm_cert
          : null;
        cert?.forEach(form => {
          totalScores = totalScores + form.performance_rate;
        });
        const avgPerc =
          totalScores > 0 ? Math.round((totalScores / 500) * 100) : null;
        return avgPerc;
      });
      return avg;
    },
    certComments(updatedCert) {
      this.newComments = updatedCert;
    },
    addComments() {
      this.addComment = true;
    },
    async saveComments() {
      this.addComment = false;
      const form = this.forms.find(
        ({ form_name }) => form_name === "Supplier Information"
      );
      const payload = {
        form_uuid: form.form_uuid,
        cert_json: this.newComments
      };
      await submitCert(payload);
    },
    async getProfileList() {
      this.tblBusy = true;
      await getProfileList().then(profiles => {
        this.tableData = profiles ?? [];
        if (this.tableData.length < 1){
          this.tblBusy = false;
        }
        if (profiles) {
          profiles.forEach(async profile => {
            await this.getAvgPerc(profile.uniqueId)
              .then(avgPerc => {
                profile.fff = avgPerc ?? "N/A";
              })
              .finally(() => {
                this.$refs.table.refresh()
                this.tblBusy = false
              });
          });
        }
      });
    },
    async showModal(id) {
      const search = await this.forms.find(form => {
        return form.recordNumber === id;
      });
      this.form_det = search;
      if (search) {
        this.$bvModal.show("form-modal");
      }
    },
    async getCertificate(farm_profile_id, form_name) {
      this.avgPerc = await this.getAvgPerc(farm_profile_id);
      // this.avgPerc = 78
      this.latestForm = {
        cert_json: [],
        form_data: {}
      };
      await getCertificate(farm_profile_id).then(result => {
        if (result.cert_json) {
          this.latestForm = result;
          this.$bvModal.show("certificate");
        } 
        else {
          this.$bvToast.toast(
            `The certificate for ${form_name} isn't ready yet `,
            {
              title: "Certificate not found",
              autoHideDelay: 3000,
              variant: "danger"
            }
          );
        }
      });
    },
    async getForm(profile_id) {
      await getForm(profile_id).then(result => {
        this.latestForm = result.reduce((a, b) =>
          this.$moment(a.created_date, "DD-MM-YYYY h:mm").format() >
          this.$moment(b.created_date, "DD-MM-YYYY h:mm").format()
            ? a
            : b
        );

        const locked_forms = result.filter(form => {
          return form.form_locked === "true";
        });

        locked_forms.sort((a, b) =>
          this.$moment(a.created_date, "DD-MM-YYYY h:mm").format() <
          this.$moment(b.created_date, "DD-MM-YYYY h:mm").format()
            ? 1
            : -1
        );
        const uniqueObj = Array.from(
          new Set(locked_forms.map(a => a.form_name))
        ).map(form_name => {
          return locked_forms.find(a => a.form_name === form_name);
        });

        this.forms = uniqueObj;
      });
    }
  }
};
</script>

<style scoped>
#app {
    border:1px solid #03a44d;
    width:400px;
    height:300px;
}
.file-input-container {
  text-align: right;
}
.file-input-container  input[type="file"] {
  display: none;
}
  
.file-input-container  label {
  background-color: #03a44d;
  border: solid 1px var(--ion-color-medium);
  border-radius: 4px;
  color: white;
  height: 5.6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  padding: 5px;
}
</style>
