<template>
  <!-- Risk Assessment -->
  <div class="my-4" v-if="form_det.form_type === 'risk'">
    <b-list-group class="my-4" v-if="form_det.form_name === 'List Supplier Needs'" flush>
      <b-list-group-item v-for="question in formData" :key="question.title">
        <div>
          <h5 class="text-capitalize">{{ question.title }}</h5>
          <p class="d-flex flex-column text-green">{{ question.fields.value }}</p>
        </div>
        </b-list-group-item>
    </b-list-group>
    <b-list-group v-else-if="formData.assessor" flush>
      <div v-for="form in formData" :key="form.recordNumber">
        <h4 v-if="form.assessor">Assessor</h4>
        <h4 v-else-if="form.location">Primary Supplier</h4>
        <h4 v-else>Secondary Supplier</h4>
        <b-list-group-item v-for="(name, value) in form" :key="value">
          <div>
            <h5 class="text-capitalize">{{ value }}</h5>
            <p v-if="name.latitude" class="d-flex flex-column">
              <span>Latitude: <span class="text-green">{{ name.latitude }}</span></span>
              <span>Longitude: <span class="text-green">{{ name.longitude }}</span></span>
            </p>
            <p v-else class="text-green">{{ name }}</p>
          </div>
        </b-list-group-item>
      </div>
    </b-list-group>
    <b-list-group v-else flush>
      <div v-for="group in formData" :key="group.recordNumber">
        <div v-if="group.name">
          <h4 class="text-capitalize">{{ group.name }}</h4>
          <b-list-group-item
            v-for="question in group.questions"
            :key="question.title"
          >
            <h5>{{ question.title }}</h5>
            <p class="text-green">{{ question.fields.value }}</p>
            <p class="text-green">{{ question.fields.comment }}</p>
          </b-list-group-item>
        </div>
        <div v-else>
          <b-list-group-item v-for="question in group" :key="question.title">
            <h5>{{ question.title }}</h5>
            <p class="text-green">{{ question.fields.value }}</p>
            <p class="text-green">{{ question.fields.comment }}</p>
          </b-list-group-item>
        </div>
      </div>
    </b-list-group>
  </div>

  <!-- Base Assessment -->
  <div v-else>
    <b-list-group v-for="group in formData" :key="group.recordNumber" flush>
      <div v-if="group.title">
        <h5 class="text-capitalize">{{ group.title }}</h5>
        <b-list-group-item class="border-bottom">
          <p class="d-flex flex-column">
            Compliant<span class="text-green"> {{ group.fields.compliant }}</span>
          </p>
          <p>
            Evidence<span class="text-green"> {{ group.fields.evidence }}</span>
          </p>
          <p>
            Timeline<span class="text-green"> {{ group.fields.timeline }}</span>
          </p>
          <p>
            Responsibility<span class="text-green"> {{ group.fields.responsibility }}</span>
          </p>
          <div v-if="group.fields.extra">
            <b-list-group-item
              v-for="extra in group.fields.extra"
              :key="extra.title"
            >
              <p>{{ extra.title }}</p>
              <p class="text-green">{{ extra.value }}</p>
            </b-list-group-item>
          </div>
        </b-list-group-item>
      </div>
      <div v-else>
        <h4 class="text-capitalize">{{ group.group }}</h4>
        <div v-if="group.questions.title">
          <b-list-group-item class="border-bottom">
            <h5>{{ group.questions.title }}</h5>
            <p class="d-flex flex-column">
              Compliant<span class="text-green"> {{ group.questions.fields.compliant }}</span>
            </p>
            <p>
              Evidence<span class="text-green"> {{ group.questions.fields.evidence }}</span>
            </p>
            <p>
              Timeline<span class="text-green"> {{ group.questions.fields.timeline }}</span>
            </p>
            <p>
              Responsibility<span class="text-green">
                {{ group.questions.fields.responsibility }}</span
              >
            </p>
          </b-list-group-item>
          <b-list-group-item
            v-for="extra in group.questions.fields.extra"
            :key="extra.title"
          >
            <p>{{ extra.title }}</p>
            <p class="text-green">{{ extra.value }}</p>
          </b-list-group-item>
        </div>
        <div v-else>
          <b-list-group-item
            class="border-bottom"
            v-for="(question, i) in group.questions"
            :key="i"
          >
            <h5>{{ question.title }}</h5>
            <p v-if="question.fields.location" class="d-flex flex-column">
              Latitude: <span class="text-green">{{ question.fields.location.latitude }}</span>
              <span>Longitude: <span class="text-green">{{ question.fields.location.longitude }}</span></span>
            </p>
            <p v-else class="text-green">{{ question.fields.value }}</p>
          </b-list-group-item>
        </div>
      </div>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "TableForms",
  props: ["form_det"],
  computed: {
    formData(){
      // const form = JSON.parse(this.form_det.form_data)
      return JSON.parse(this.form_det.form_data);
    }
  }
};
</script>

<style>
.list-group-item {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
</style>
